.title-label {
  text-transform: uppercase;
  color: #C7C7C7;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
}

input[type="text"] {
  &:focus {
    box-shadow: none;
    background: none;
  }
}

.items-list {
  list-style-type: none;
  padding-left: 0;
  font-size: 14px;
  line-height: 18px;
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}

.checkout {
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  &__main-info {
    padding: 4px 30px 0 57px;
    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }
  &__member-info {
    padding-left: 57px;
    padding-right: 37px;
    @media screen and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__date {
    margin-bottom: 4px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 6px;
    }
  }
  &__description {
    font-size: 14px;
    margin-bottom: 26px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  &__member-field {
    text-overflow: ellipsis;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  &__member-field-group {
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      margin-bottom: 22px;
    }
    &:last-child {
      margin-bottom: 14px;
    }
  }
  &__input-wrapper {
    margin: 37px 32px 32px 56px;
    @media screen and (max-width: 767px) {
      margin: 37px 15px 32px 15px;
    }
    &.radio-input {
      margin: 37px 15px 32px 32px;
      @media screen and (max-width: 767px) {
        margin: 37px 15px 32px 15px;
      }
      @media screen and (min-width: 321px) and (max-width: 599px) {
        padding: 60px 0 20px!important;
      }

    }
    .offer-label {
      img {
        display: inline-block;
        margin-left: 10px;
        margin-bottom: 5px;
      }
    }
    &.promocode-input {
      position: relative;
      @media screen and (max-width: 767px) {
        margin: 37px 15px 32px 15px;
      }
      &:before {
        content: 'Промокод Сертификат';
        color: #a1a1a1;
        font-size: 9px;
        font-weight: 400;
        line-height: 14px;
        text-transform: uppercase;
        text-align: center;
        padding-top: 25px;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-84%);
        background: url('../../../styles/assets/images/sv/promocode.svg');
        width: 98px;
        height: 60px;
      }
      .promocode-input-badge {
        // position: absolute;
        // top: -17px;
        // right: -9px;
      }
      .applied-promocode {
        top: 12%;
      }
    }
    &.promocode-input.en {
      &:before {
        content: '';
        background: url('../../../styles/assets/images/sv/promocode_en.svg');
      }
    }
    input[type="checkbox"] {
      display: none;
      &:checked + label {
        &:before {
          background: #000;
        }
      }
    }
    input[type="text"] {
      height: 25px;
      &.has-error {
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    label {
      position: relative; 
      width: calc(100% - 17px);
      padding-right: 55px;
      height: 40px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
      }
      cursor: pointer;
      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid black;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../../styles/assets/images/sv/tick_white.svg);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.subscription_pay{
      label{
        display: inline-flex;
        align-items: center;
        width: auto;
        border: 1px solid #000;
        border-radius: 10px;
        padding: 10px 15px;
        margin-bottom: 0;
        &::before{
          position: static;
          transform: none;
          margin-right: 12px;
        }
        &:after{
          left: 15px;
        }
      }
      .subscription_info{
        margin-left: 15px;
        font-size: 17px;
        .subscription_sale{
          margin-top: 4px;
        }
        .subscription_balance{
          .subscription_balance_value{
            margin-left: 5px;
          }
          .subscription_balance_old{
            text-decoration: line-through;
          }
          .subscription_balance_new{
            margin-left: 5px;
          }
        }
      }
      input[type='checkbox']:checked {
        & + label{
          color: #01A830;
          border-color: #01A830;
          &::before{
            background: #01A830;
            border-color: #01A830;
          }
        }
        & ~ .subscription_info{
          color: #01A830;
        }
      }
    }
    textarea {
      &#comment {
        min-height: 100px;
      }
      &:focus {
        box-shadow: none;
        background: none;
      }
      &.has-error {
        margin-bottom: 0;
      }
      line-height: 25px;
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    a {
      color: #000;
      text-decoration: underline;
      font-family: "Basis Grotesque Pro";
      font-size: 16.12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.242px;
    }
  }
  .divider {
    margin-top: 26px;
  }
}

#promocode {
  height: 25px;
  width: calc(100% - 120px);
}

.applied-promocode {
  position: absolute;
  top: -6px;
  right: -9px;
  background: url(../../../styles/assets/images/sv/promocode-active-card-left.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 111px;
  height: 85px;
}

.promo-text {
  font-family: 'Menoe Grotesque Pro';
  text-transform: uppercase;
  transform: rotate(-15deg);
  text-align: center;
  margin-top: 27px;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.5;
}

::-webkit-input-placeholder {
  white-space: pre-line;  
  position: relative; 
}
::-moz-placeholder {
  white-space: pre-line;  
  position: relative;
}
:-moz-placeholder {
  white-space: pre-line;  
  position: relative;
}
:-ms-input-placeholder {
  white-space: pre-line;  
  position: relative;
}

.my-own-house {
  label {
    margin-bottom: 0;
  }
}
